// update url
const updateUrl = "http://193.112.220.118/tpu/update/update.json";

// 这样写是因为还有一个项目也是用的同样的升级机制,但是那个项目是多语言的,所以这里这么写是为了和那个项目的结构一样
function _$t(k) {
  let lan = {
      'mApp.updateTip': '发现新版本,是否更新',
      'mApp.updating': '更新中...',
      'mApp.quitTip': "再按一次退出"
  }
  return lan[k];
}

let m = {
  created() {
      this.initApp();
  },
  methods: {
    initApp() {
      if(!this.$t) {
        this.$t = _$t;
      }      
      var that = this;
      function plusReady() {
        setTimeout(() => {
          that.checkUpdate();
        }, 1000);
        // 隐藏滚动条
        plus.webview.currentWebview().setStyle({
          scrollIndicator: 'none'
        });
        var _flag = false;
        var backButtonPress = function(){
          //console.log(location.href);
          var hash = location.hash.split("?")[0];
          if (hash != "#/home" && hash != "#/login" && location.hash.indexOf("#/dig?ifhave=false") == -1) {
            history.back();
            return true;
          }
  
          setTimeout(function () {
            _flag = false;
          }, 2000);
          if (_flag) {
            quitApp();
          } else {
            plus.nativeUI.toast(that.$t("mApp.quitTip"));
            _flag = true;
          }
          return false;
        }        
        // Android处理返回键
        plus.key.addEventListener('backbutton', backButtonPress, false);
        // 手机调试的时候 如果不删除后退的监听, 就会绑定多次 这里用动态添加beforeDetroy方法增加可读性
        that.$once('hook:beforeDestroy', function() {
          plus.key.removeEventListener('backbutton', backButtonPress)
        })        
        //    //mui.back = function(){};
        compatibleAdjust();
        //屏幕常亮
        // plus.device.setWakelock( true );
        //横屏(在调试的时候使用 , 发布以后在manifest里面设置了 所以这就不用了)
        //plus.screen.lockOrientation( "landscape-primary" );
      }

      if (window.plus) {
        plusReady();
      } else {
        document.addEventListener('plusready', plusReady, false);
      }
      // DOMContentLoaded事件处理
      var _domReady = false;
      document.addEventListener('DOMContentLoaded', function () {
        _domReady = true;
        compatibleAdjust();
      }, false);
      // 兼容性样式调整
      var _adjust = false;

      function compatibleAdjust() {
        if (_adjust || !window.plus || !_domReady) {
          return;
        }
        _adjust = true;
        // iOS平台使用div的滚动条
        //  if('iOS'==plus.os.name){
        //    document.getElementById('content').className='scontent';
        //  }
        // 预创建二级窗口
        //  preateWebviews();
        // 关闭启动界面
        setTimeout(function () {
          plus.navigator.closeSplashscreen();
        }, 500);
      }
      //退出app
      function quitApp() {
        plus.runtime.quit();
      }
    },
    checkUpdate() {
      //之所以使用plus中的xhr 是因为调试的时候如果需要访问一个不是在本app里面的地址,用普通的xhr,发请求会被报跨域错误.当然也可以使用vue的代理
      var that = this;
      var xhr = new plus.net.XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            var o = JSON.parse(xhr.responseText);
            that.checkVersion(o);
          } else {
            console.log("xhr request failed：" + xhr.readyState);
          }
        }
      }
      xhr.open("GET", updateUrl);
      xhr.send();
    },
    checkVersion(o) {
      var that = this;
      plus.runtime.getProperty(plus.runtime.appid, function (data) {
        var version = data.version ///获取本地的版本。
        if (o.version > version) {
          plus.nativeUI.confirm(that.$t("mApp.updateTip"), function (e) {
            console.log(e);
            if (e.index == 0) {
              if (o.type == 1) { // 热更新
                that.dlUpdate(o.url);
              } else if (o.type == 2) { //正常更新
                plus.runtime.openURL(o.url);
              }

            }
          });
        }
      });
    },
    dlUpdate(aUrl) {
      var that = this;
      //plus.nativeUI.toast("开始下载最新版本");
      updateWin.init(this.$t, this);
      updateWin.start();
      plus.downloader.createDownload(aUrl, {}, function (d, status) {
        if (status == 200) {
          that.doInstall(d.filename); // 安装wgt方法
          updateWin.finish();
        } else {
          updateWin.hide();
          plus.nativeUI.toast("download failed!");
        }
      }).start();
    },
    doInstall(path) {
      //plus.nativeUI.toast("下载成功,准备更新");
      // console.log("path---" + path)
      plus.runtime.install(
        path, {},
        function () {
          // 更新完成后删除更新包
          plus.io.resolveLocalFileSystemURL(
            path,
            function (entry) {
              entry.remove(
                function () {
                  console.log("fail delete success==" + path);
                  plus.runtime.restart();
                },
                function () {
                  console.log("fail delete failed==" + path);
                  plus.runtime.restart();
                }
              );
            },
            function (err) {
              //plus.nativeUI.alert("路径不存在");
              //plus.runtime.restart();
            }
          );
        },
        function (err) {
          plus.nativeUI.toast("install failed." + JSON.stringify(err));
        }
      );
    }
  }
}
let updateWin = {
  tmpl: `
    <div class="c-update-mbox">
      <div class="c-update-text">更新中...</div>
      <div class="c-update-progress">
        <div class="c-update-progress-inner"></div>
      </div>
    </div>
  `,
  style: `
  .c-update-box {
    position:fixed;
    z-index: 999998;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; 
    background: rgba(255, 255, 255, .4);
  }
  .c-update-mbox {
    position: absolute;
    z-index: 999999;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    width: 260px;
    height: 70px;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 5px;
    padding: 8px;
    border: solid 1px #CCCCCC;
  }
  .c-update-text{
    font-size: 15px;
    line-height: 24px;
    text-align:center;
  }
  .c-update-progress {
    width: 90%;
    height:14px;
    margin: 16px auto;
    border: solid 1px #CCCCCC;
    border-radius: 2px;
    overflow: hidden;
  }
  .c-update-progress-inner {
    height: 100%;
    width: 0;
    background:#07c160;
  }
  `,
  up: null,
  timer: null,
  init($t, ctx) {
    if(this.up){
      return;
    }
    let box = document.createElement('div');
    box.className = "c-update-box";
    box.style.display = "none";
    box.innerHTML = this.tmpl;
    box.querySelector(".c-update-text").textContent = $t.call(ctx, "mApp.updating");
    document.body.appendChild(box);
    this.up = box;
    let s = document.createElement("style");
    s.textContent = this.style;
    document.getElementsByTagName("head")[0].appendChild(s);
  },
  show(n) {
    if(!this.up){
      console.log("init must run first")
      return;
    }
    this.up.style.display = "block";
    this.up.querySelector(".c-update-progress-inner").style.width = n + "%";   
  },
  start() {
    let v = 10;
    this.timer = setInterval(() => {
      v = v + parseInt(Math.random() * 10);
      if(v > 95) {
        v = 95;
        clearInterval(this.timer);
        this.timer = null;
      }
      this.show(v);
    }, 500);
  },
  finish() {
    clearInterval(this.timer);
    this.timer = null;
    this.show(100);
    setTimeout(()=> {
      this.hide();
    }, 3000)
  },
  hide() {
    if(!this.up){
      console.log("init must run first");
      return;
    }
    this.up.style.display = "none";
    this.up.querySelector(".c-update-progress-inner").style.width = 0;
    clearInterval(this.timer);
    this.timer = null;
  }
}

export default m;
