module.exports = {
	tba: {
		duocang: '多倉',
		toucun: '頭寸',
		kongcang: '空仓',
		zcc: '空倉',
		kcl: '開倉量',
		zdcw: '最大倉位',
		double: '雙向對沖',
		zytj: '止盈條件',
		agent_reward_raise: '加權分紅',
		level_reward_raise: '等級分紅',
		bcsz: '補倉設定',
		bcje: '補倉金額',
		marting_d: '定價馬丁',
		wave_x: '智慧海浪',
		wave_f_x_double: '智慧海浪',
		zhuan_shouxufei: '轉帳手續費為',
		shouxufei_danwei: '/筆',
		zhuanzhangjilu: '轉帳記錄',
		tibijilu: '提幣記錄',
		unlock_price: '解套資金',
		InOperation: '運行中',
		Closed: '已關閉',
		AntiRiotFall: '防暴跌',
		CycleTime: '時間週期',
		DeclineRange: '下跌幅度',
		UnravelingMode: '解套模式',
		jinri: '今日:',
		shouyipaihangbang: '收益排行榜',
		tuichudenglu: '登出',
		kaitongvipxiangfuli: '開通會員享福利',
		lijikaitong: '立即開通',
		download: '下載APP',
		AccountLogin: '帳號登入',
		Successqinggua: '清理掛單成功',
		failqinggua: '清理掛單失敗',
		Qingliguadan: '清理掛單',
		wave_f_coin: '增幣策略',
		bindEmailSuccess: '綁定郵箱成功',
		quedingbangding: '確定綁定',
		qingshuruyouxiang: '請輸入郵箱',
		qingshuruyouxiangzhanghao: '請輸入郵箱帳號',
		duo: '多',
		kong: '空',
		allchecked: '全選',
		bian1: '幣安',
		huobi1: '火幣',
		okex1: 'OKX',
		zhiyingjiage: '止盈價格',
		manual_f_long: '手動策略多',
		manual_f_short: '手動策略空',
		beishu: '倍數',
		wangge_x: '解構馬丁',
		wave_f_long: '海浪對沖多',
		wave_f_short: '海浪對沖空',
		look_all: '查看全部',
		weighted_share_reward_raise: '全球分紅',
		yuerbuzu: '餘額不足，請先充值！',
		shangxueyuan: '商学院',
		shangxueyuan: '商學院',
		insufficient_gas: '燃料費不足',
		not_valid_membership: '用戶不是有效期會員',
		wrong_params: '參數錯誤',
		failed_to_save: '保存設置失敗',
		check_params: '請檢查參數',
		empty_bot_id: '機器人id為空',
		wrong_bot_id: '機器人id錯誤',
		server_error: '服務器异常',
		zhisunjiage: '止損價格',
		dangqianzijin: '當前資金',
		dangqianchicang: '當前持倉',
		queshaojiaoyimiyao: '缺少交易所私密金鑰，立即綁定？',
		lijibangdin: '立即綁定',
		jiaoyileixin: '交易類型',
		im_teacher: '我是老師',
		add_lianghua: '添加量化',
		ljyjsr: '累計傭金收入',
		xianshimingcheng: '顯示名稱',
		xuanzhejiaoyisuo: '選擇交易所',
		jiaoyifangshi: '交易方式',
		zhanghuzongzichan: '帳戶總資產',
		weikaiqi: '未開啟',
		jinxinzhong: '進行中',
		shenhezhong: '稽核中',
		yichehui: '已撤回',
		yijujue: '已拒絕',
		xiugaixinxi: '修改資訊',
		kaiqi: '開啟',
		tingzhilianghua: '停止量化',
		chehuishenhe: '撤回稽核',
		quanbutingzhi: '全部停止',
		quedingyaokaiqilianghuama: '確定要開啟量化嗎？',
		queding: '確定',
		zanshibuyao: '暫時不要',
		quedingyaotingzhilianghuama: '確定要停止量化嗎？',
		tingzhihoubunengjixu: '停止後將不能繼續帶單',
		quedingyaochehuishenqingma: '確定要撤回申請嗎？',
		quedingyaotingzhiall: '確定要停止全部量化嗎？',
		success_che: '撤回成功',
		success_stop: '停止成功',
		success_open: '開啟成功',
		tuoguanxiangqing: '託管詳情',
		putongVIP: '普通會員',
		xiugaichushituoguanzijin: '修改初始託管資金',
		hdsy: '獲得收益',
		syfd: '收益幅度',
		mrsy: '每日收益',
		xiugaichenggong: '修改成功',
		my_tuoguanxiangqing: '我的託管詳情',
		tuoguancishu: '託管次數',
		daidanjiner: '帶單金額',
		nidezhanghaohaiweibangding: '您的帳號還沒有綁定',
		pingtaizanwufashiyong: '平臺暫無法使用託管功能',
		qubangding: '去綁定',
		xiugailianghua: '修改量化',
		qingshuruxianshimingcheng: '請輸入顯示名稱',
		shoufeibili: '收費比例',
		qingshurushoufeibili: '請輸入收費比例',
		zhanghuzongzichan: '帳戶總資產',
		qingshuruzhanghuzongzichan: '請輸入帳戶總資產',
		tijioashenqing: '提交申請',
		shoufeilvbuhefa: '收費率不合法',
		
		fxtishi: '風險提示',
		agree: '同意',
		refuse: '拒絕',
		Contract: '張',
		xiugaifangxiang: '修改方向,原有持倉將被清倉',
		celue: '策略',
		zuoduo: '做多',
		zuokong: '做空',
		zuodanfangxiang: '做單方向',
		zhangfudie: '漲幅跌',
		xianhuojiaoyi: '現貨交易',
		heyuejiaoyi: '合約交易',
		kaifazhong: '開發中',
		new_price: '最新價',
		chengweilaoshi: '成為老師',
		woyaotuoguan: '我要託管',
		wodetuoguan: '我的託管',
		gensuizhe: '跟隨者',
		onedayshouyi: '24小時收益',
		fenchengbili: '分成比例',
		zongdaidanrenshu: '總帶單人數',
		nianhuashouyi: '年化收益',
		jinrishouyi: '今日收益USDT',
		leijishouyi: '累計收益USDT',
		zhengzaijinxin: '正在進行',
		yijieshu: '已結束',
		chushituoguanzijin: '初始託管資金',
		huodeshouyi: '獲得收益USDT',
		tuoguanshijian: '託管時間',
		xiugaizijin: '修改資金',
		wodeleijishouyi: '我的累計收益',
		wodetuoguancishu: '我的託管次數',
		xiangqing: '詳情',
		chushizijin: '初始資金',
		shouyilv: '收益率',
		ti_tingzhi: '停止託管後將不再跟隨老師操作!',
		tuoguanzijin: '託管資金',
		zuidakeyongyue: '最大可使用餘額',
		qingshuru: '請輸入',
		quanbutuoguan: '全部託管',
		querentuoguan: '確認託管',
		cangwei: '倉位',
		jinsantianshouyi: '近三天日化收益',
		yuqinianhuashouyilv: '預期年化收益率',
		zhengzaidaidanrenshu: '正在帶單人數',
		piliangtankuang: '僅針對已經勾選的所有幣種進行操作',
		transfer_accounts: '轉帳成功',
		refund: '退款',
		global_reward_raise: '全球分紅',
		trusteeship_brokerage_reduce: '託管傭金扣除',
		trusteeship_brokerage_add: '托管佣金',
		xianhuo_gas: '燃料費',
		deposit: '充值',
		team_reward: '團隊獎',
		direct_promote: '直推獎',
		indirect_promote: '間推獎',
		flat_level_reward_raise: '平級獎',
		buy_active_code: '購買會員卡',
		withdraw: '提現',
		transfer: '轉帳',
		system_deposit: '系統存幣',
		frozen_asset: '凍結資產',
		transfer_out_fee: '轉出手續費',
		withdraw_deny: '拒絕提幣',
		Delete: '删除',
		sure_to_delete: '確定删除嗎?',
		delete_ok: '删除成功！',
		delete_no: '已取消删除！',
		SetAPI: '設定API',
		DeleteAPI: '删除API',
		bucangbeishu: '補倉倍數',
		marting: '智慧馬丁',
		wangge_f: '智慧馬丁',
		bottom: '底部追跡',
		wave: '遊雲策略',
		wave_f: '遊雲策略',
		Single: '單次迴圈',
		Continuous: '連續迴圈',
		APIAuthorization: 'API授權',
		// -----202230427 start----
		okx:'OKX開通',
		// -----202230427 end----

		// -----20210414 start----
		transfer_in: '轉入',
		transfer_out: '轉出',
		SuccessfulPurchase: '购买成功！',
		AvailableBalance: '最大可用餘額',
		Processing: '处理中...',
		Stoping: '停止中...',
		Clearanceing: '清倉中...',
		GetParameters: '獲取參數中...',
		qidong: '啟動中...',
		qidongsuccess: '啟動成功',
		FailedAcquireRobot: '获取机器人失败',
		Loading: '加载中...',
		SaveParametersSucceeded: '保存参数成功！',
		SaveParametersFailed: '保存参数失败！',
		SaveStartSuccessfully: '保存并启动成功！',
		CannotEmpty: '参数不能为空！',
		robot: '机器人',
		StopSuccess: '停止成功',
		PleaseSetTogetInitializationParameters: '请先点击设置，获取初始化参数',
		OnceMaxAction20: '一次不可操作20个交易对',
		SelectOneMore: '请至少选择一个交易对',
		successNumber: '成功个数',
		failNumber: '失败个数',
		SaveingParametersbatch: '批量保存参数中...',
		errorWithdrawAmount: '提币数量不对',
		// -----20210414 end----
		// -----20210413 start----
		SelectPosition: '選擇倉位',
		Profits: '%盈利額',
		UserHostingAgreement: '《用戶托管協議》',
		TrusteeshipRiskyShouldBeCaution: '托管有風險,使用需謹慎',
		ServiceRights: '服務權益',
		enteryourMobilenumber: '請填寫手機號！',
		PleaseCheckUserAgreement: '請勾選《用戶協議》',
		RegistrationSuccessfulReturntoLogin: '註冊成功！請返回登錄頁面登錄！',
		PleaseEnterYourUsername: '請輸入用戶名',
		AnnouncementDetails: '公告詳情',
		PasswordLogin: '密碼登陸',
		VerificationCodeLogin: '驗證碼登陸',
		// ------20210413[end]-----
		// -----20200409 start----
		APIset: '設置API',
		codeHasSend: '驗證碼已發送至：',
		enterAPIKey: '請輸入API KEY',
		enterSecretKey: '請輸入Secret KEY',
		enterPassphrase: '請輸入Passphrase',
		setWord1: '獲取到',
		setWord1_2: '的API KEY用於同步',
		setWord2: '輸入或者復制粘貼你的',
		setWord3: '立即開戶',
		walletRecord: '錢包記錄',
		fuelFee: '燃料費',
		totalReward: '量化總收益',
		today: '今日：',
		total: '總：',
		addUp: '累計',
		toBeVIP: '馬上成為VIP',
		setupMarting: '馬丁設置',
		Onceloop: '單次循環',
		ci: '次',
		cycle: '周期',
		extentContinuousDecline: '連續下跌的幅度',
		MemberClub: '會員俱樂部',
		deadline: '到期',
		youarenotVip: '您還不是會員',
		handlingMembership: '辦理會員',
		yes: '確定',
		chongbiAddress: '充幣地址',
		choosebi: "選擇幣種",
		reciceMoneyAddress: '收款地址',
		reciveAccount: '接收賬號',
		reciveAccountName: '接收賬號名',
		tibiConfirmInfo: '提幣信息確認',
		RiskStatement: '风险提示',
		riskTipsText: {
			one: '1、請仔細核對提幣地址信息, 提幣一旦完成,資產將無法追回 ;',
			two: '2、請警惕任何傳銷、非法集資、詐騙等違法行為, 防範“搬磚套利”、“帶單操盤”等騙局, 避免造成財產損失 ;',
			three: '3、任何宣稱為合作的投資均為詐騙, 您可以點擊官方驗證通道, 查驗識別其是否為官方渠道 ;',
			four: '4、數字資產投資是高風險行為, 請註意識別傳銷幣、山寨幣等投資項目 ;',
			five: '5、如有任何疑問, 請在幫助中心聯系客服解決 .',
		},
		tibiAddressError: '提幣地址不對',
		checkNetwork: '請檢查網絡',
		SecurityVerification: '安全驗證',
		verificationCode: '手機驗證碼',
		PasswordInput: '密碼輸入',
		VerificationCodeSentSuccess: '驗證碼發送成功',
		changeLoginPass: '修改登錄密碼',
		setTransferPass: '設置交易密碼',
		setDefaultExchange: '設置默認交易所',
		inputLoginPwd: '請輸入登錄密碼',
		changePwd: '修改密碼',
		copyVersion: '版本號',
		weiXin: '微信',
		trusteeship: '託管',
		trusteeship_close: '停止託管',
		trusteeshipClose: '是否關閉當前套餐？',
		trusteeshipClose_yes: '是',
		trusteeshipClose_no: '否',
		trusteeshipClose_success: '關閉套餐成功！',
		trusteeshipOpen_success: '開通套餐成功！',
		trusteeshipPlease_check: '請先勾選用戶託管協議！',
		uniswap: 'uniswap',
		dealNow: '立即交易',
		noRobot: '暫無機器人',
		startNow: '立即開啟',
		oneButtonReplenishment: '一鍵補倉(USDT)',
		oneButtonReplenishment1: '一鍵補倉(張)',
		cao_num: '超出持倉數量',
		ReplenishmentQuantity: '補倉數量:',
		SelloutQuantity: '賣出數量:',
		MartinDetails: '馬丁詳情',
		BottomTrackingDetails: '底部追蹤詳情',
		stopRobotFirst: '請先停止機器人',
		SuccessReplenish: '補倉成功',
		SuccessSellout: '賣出成功',
		failReplenish: '補倉失敗',
		SuccessClearance: '清倉成功',
		failSellout: '賣出失敗',
		failClearance: '清倉失敗',
		OrderStatus: '訂單狀態',
		helpGuide: '幫助指南',
		resetPassword: '重置密碼',
		nextStep: '下一步',
		enterMobileNumber: '請輸入您的手機號',
		EnterReceivedVerificationCode: '輸入收到的驗證碼',
		Resend: '重新發送',
		// -----20200409 end----
		historyRecord: '歷史記錄',
		descriPtion: '說明',
		filterType: '篩選類型',
		MyProfit: '我的盈利',
		todayProfit: '今日盈利',
		AccumulatedProfit: '累計盈利',
		order: '訂單',
		date: '日期',
		switch_pack: '兌換套餐',
		DealFor: '交易對',
		exchange: '交易所',
		running: '運行中',
		stop: '已停止',
		all: '全部',
		unconfig: '未配置',
		manualBuchang: '手動補倉',
		manualMaichu: '手動賣出',
		savefail: "保存失敗",
		Profit: '盈利',
		Activation1code: '激活碼（已經用）',
		MyActivationCode: '我的激活碼',
		PurchaseQuantity: '購買數量',
		payment: '支付',
		PurchaseActivationCode: '購買激活碼',
		BuyNow: '立即購買',
		setup: '設置',
		start_up: '啟動',
		stop_it: '停止',
		assets: '資產',
		ChainType: '鏈類型',
		Receivingaddress: '收貨地址',
		copy: '復制',
		explain: '請勿向上述地址充值任何非USDT-TRC20資產，否則資產將不可退回',
		cancel: '取消',
		title: '策略詳情',
		PositionAmount: '持倉金額',
		AveragePositionPrice: '持倉均價',
		Numberofpositionsfilled: '已補倉次數',
		PositionQuantity: '持倉數量',
		CurrentPrice: '當前價格',
		Up: '利潤率',
		StrategyRelated: '策略相關',
		OpeningAmount: '開倉金額',
		Replenishmenttimes: '補倉次數',
		Profitmargin: '止盈幅度',
		zhiyingcishu: '止盈次數',
		OneProfitmargin: '每單止盈幅度',
		Suofanglv: '寬度縮放率',
		Zuidabucangcishu: '最大補倉次數',
		Wanggekuandu: '網格寬度',
		Bucangjinersuofanglv: '補倉金額縮放率',
		gangganbeishu: '杠杆倍數',
		Profitstopcallback: '止盈回調',
		Marginofreplenishment: '補倉幅度',
		Makeupcallback: '補倉回調',
		Horizontalpushornot: '是否平推',
		pingtuifudu: '平推幅度',
		shoucibucang: '首次補倉',
		di2cibucang: '第2次補倉',
		di3cibucang: '第3次補倉',
		di4cibucang: '第4次補倉',
		di5cibucang: '第5次補倉',
		di6cibucang: '第6次補倉',
		di7cibucang: '第7次補倉',
		di8cibucang: '第8次補倉',
		di9cibucang: '第9次補倉',
		di10cibucang: '第10次補倉',
		di11cibucang: '第11次補倉',
		di12cibucang: '第12次補倉',
		di13cibucang: '第13次補倉',
		Cyclesetting: '周期設置',
		DeclineRange: '下跌幅度',
		Policyoperation: '策略操作',
		Policysettings: '策略設置',
		Clearingandselling: '清倉賣出',
		Wavegrid: '遊雲策略',
		article: '資訊',
		WelcometoRainbow: '',
		Passwordlogin: '密碼登錄',
		SMSlogin: ' 短信登錄',
		Forgotpassword: '忘記密碼？',
		LogintoagreeUserAgreement: '《用戶協議 》 登錄即同意，《用戶協議》',
		Obtain: '獲取',
		Confirmpasswordagain: '再次確認密碼',
		registered: '註冊',
		invitefriends: '邀請好友',
		home: "首頁",
		vcode: "驗證碼",
		Spot: '現貨 ',
		USDTcontract: 'USDT合約',
		CurrencyBasedcontract: '幣本位合約',
		Bottomloop: '底部循環',
		Position: '持倉',
		Floating: '浮盈',
		Thepositionhasbeencovered2times: '已補倉2次',
		Batchsettings: '批量設置',
		Batch_stop: '批量停止',
		Bulk_clearing: '批量清倉',
		Usedusdt: '已用usdt',
		Usdtavailable: '可用',
		AccountassetsUSDT: '賬戶資產（USDT)',
		Topup200usdt: '充值200usdt',
		activation: '激活',
		ImmediatelyactivatetheRainbowintelligentquantitativerobot: '立即激活智能量化機器人',
		Todaysearnings: '今日收益',
		Teamtodaysearnings: '團隊今日收益',
		DirectMembership: '直推會員',
		DirectMembershiplist: '直推會員列表',
		activated: '已激活',
		inactivated: '未激活',
		Directpushtotalrevenue: '直推總收益',
		Directlypushtodaysearnings: '直推今日收益',
		Numberofteam: '團隊人數',
		Totalteamrevenue: '團隊總收益',
		sequence: '序列',
		accountnumber: '賬號',
		Directpush: '直推',
		Team: ' 團隊 ',
		Totalrevenue: '總收益',
		Orderid: '訂單id ',
		Cumulativeincomedaily: '累計收益（每日)',
		Buyin: '買入 ',
		TransactionRecord: '交易記錄',
		Sell: '賣出',
		Thenumberoftransactions: '成交數量',
		Averagetransactionprice: '交易均價',
		transactionfee: '交易費用 ',
		Handlingfee: '手續費',
		Log: '日誌',
		OperationID: '操作ID',
		News: '資訊',
		Notice: '通知',
		news: '新聞',
		Invitefriendstoenjoya30bonus: '邀請好友一起享受30％的獎金',
		sendinvitationTofriends: '發送邀請好友',
		Friendscompleteregistrationandactivation: '好友完成註冊並激活',
		Getrewardsinproportion: '獲得相應比例獎勵,',
		Myinvitationlink: '我的邀請鏈接',
		Myinvitationcode: '我的邀請碼',
		Generateinvitationposter: '生成邀請海報',
		Popular: '熱門',
		Pleaseentercurrency: '請輸入幣種',
		searchfor: '搜索',
		Batchclearance: '批量清倉',
		Bulkreplenishment: '批量補倉',
		Batchstart: '批量啟動',
		MotherboardMainarea: '主板-主區',
		InnovationZone: '創新區',
		parameter: '參數',
		Cycleway: '循環方式',
		save: '保存',
		Saveandstart: '保存並啟動',
		Withdraw: '提幣',
		Securitycenter: '安全中心',
		tool: '工具',
		Helpcenter: '幫助中心',
		aboutus: '關於我們',
		dropout: '退出',
		Helpdescription: '幫助說明',
		HelloIamXiaoRcanIhelpyouYoucanclickonthecorrespondingquestiontounderstandtheworkingprincipleofRainsintelligentquantitativerobot: '您好，我是小R，我能幫您嗎？ 您可以單擊相應的問題以了解 Rain 智能量化機器人的工作原理',
		NoviceOperationGuide: '新手操作指南',
		Rainsstrategyparameterfunctionsettinginstructions: 'Rain的策略參數功能設置說明',
		Rainsrecommendationrewardmechanism: 'Rain的推薦獎勵機制',
		Aboutrates: '關於費率',
		Aboutdepositandwithdrawalfees: '關於充提扣費',
		Chainname: '鏈名稱',
		PleasedonotdepositanynonTRC20USDTassetstoheaboveaddressotherwisetheassetswillnotberetrieved: '請勿向上述地址充值任何非TRC20_USDT資產，否則資產將不可找回',
		Minimumwithdrawalamount10: '最小提幣數量',
		zuyi: '注意：平臺的最低提款金額為',
		shouxufei: '提款手續費為',
		shouxufei_danwei: 'u/筆',
		shouxufei_danwe2: '/筆',
		Arrivalquantity: '到賬數量',
		Seagridy: '海網格',
		Continuousloop: '連續循環',
		begin: '開始',
		AlreadyhaveanaccountTologin: '已有賬號？去登陸',
		YouNeedEmail: "為了提現交易等操作，請先設置郵箱",
		email: '郵箱',
		strategy_setup: '策略設置',
		defaultExchange: '默認交易所',
		defaultVcode: '默認驗證碼',
		defaultLang: '默認語言',
		VIPmember: 'VIP會員',
		vcode_title: '設置默認驗證碼',
		sms: '短信',
		vcode_msg: '提現和交易需要驗證碼，請選擇默認驗證碼發送方式',
		savesucc: '保存成功',
		defaultExchange_msg: '選擇您最常用的交易所',
		huobi: '火幣',
		bian: '幣安',
		OKEx: 'OKX ',
		lang: '設置語言',
		en: '英語',
		zh: '中文',
		lang_msg: '請選擇默認界面和操作語言',
		checkversion: '檢查版本',
		currency: '交易對',
		strategy: '策略',
		wangge: '智能馬丁',
		wangge2: '底部追蹤',
		wangge3: '遊雲策略',
		wangge_m: '海浪趨勢',
		quant: '量化',
		trade: '交易',
		aritcle: '資訊',
		my: '我的',
		contract: '合約',
		community_profit: '社區收益',
		my_profit: '個人收益',
		begin_quant: '開始量化',
		quant_welcome: '歡迎使用TBA合約量化系統',
		myteam: '我的團隊',
		follow_setup: '跟隨設置',
		follow_methods: '跟隨方式',
		follow_all: '跟隨所有幣種',
		token_select_setting: '幣種設置',
		asset_scale: '凈值縮放',
		select_trade_strategy: '選擇交易策略',
		Bill: '賬單',
		transaction: '交易',
		AnnouncementonthelaunchofRainbowQuantitativeRobot: '關於量化機器人上線公告...',

	},
	xinzeng: {
		qianbao: "錢包",
		faxian: "發現",
		xiaoxi: "消息",
		wode: "我的",
		bangdingtibidizhi: "請綁定提幣地址",
		tibidizhi: "提幣地址",
		xuanzebi: "選擇更多幣種",
		tibi: "提幣",
		buzhichi: "不支持通證類型",
		fuzhichengg: "復制成功",
		chongbi: "充幣",
		zichan: "資產",
		exchange_tt: "兌換",
		shandui: "閃兌",
		zhuanz: "轉賬",
		zanweikaif: "暫未開放",
		xi: "將會抵押到虛擬銀行,贖回時有2周鎖定期",
		wodezichan: "我的資產",
		keyongzichan: "可用資產",
		chankanxiangqing: "查看詳情",
		chakangengduo: "查看更多",
		zhuanzhang: "轉賬",
		zhiyawakuang: "質押挖礦",
		zhengben: "賬本",
		defipool: "挖礦理財",

		shoukuan: "收款",
		chuangshixinbi: "創世新幣",
		hongbao: "紅包",
		shengou: "申購",
		zuixinhangqing: "最新行情",
		zanweikaitobng: "暫未開通"

	},
	tankuang: {
		no: "取消",
		yes: "確認"
	},
	login: {
		// ----20210409 start---- //
		welcome: '',
		userLawB: '登錄即同意',
		userLaw: '《用戶協議》',
		PleaseEnterRightPhoneNumber: '請輸入正確的手機號',
		PleaseEnterRightVerificationCode: '請輸入正確的驗證碼',
		registAccount: '註冊賬號',
		PleaseEnterYourPasswordAgain: '請再次確認密碼',
		PleaseEnterYourCode: '請輸入邀請碼',
		AgreeLaw: '我同意',
		and: '和',
		mianzetiaokuan: '《免責條款》',
		regist: '註冊',
		hasAccount: '已有賬號？去',
		// ----20210409 end---- //
		emailorphone: '手機號或郵箱',
		VerificationCodeLogin: '驗證碼登錄',
		PasswordToLogin: '密碼登錄',
		PleaseEnterYourCellPhoneNumber: '請輸入手機號',
		PleaseEnterVerificationCode: '請輸入驗證碼',
		getCode: '驗證碼',
		login: '登錄',
		login_false: '用戶名或密碼錯誤',
		login_failed: '驗證碼不對',
		No_register: '請先註冊',
		WalletUsersLogInDirectly: '錢包用戶直接登錄',
		PleaseEnterYourMobilePhoneNumber: '請輸入手機號/郵箱',
		PleaseEnterYourPassword: '請輸入密碼',
		RegisteredAccountNumber: '註冊賬號',
		ForgetThePassword: '忘記密碼',
		PleaseInputMobilePhoneNumber: '請輸入賬號',
		// 請輸入手機號',
		PleaseInputAPassword: '請輸入密碼',
		PleaseEnterEheVerificationCode: '請輸入驗證碼',
		MobileRegistration: '手機號註冊'


	},

	common: {
		home: '首頁',
		personal: '個人中心',
		lang: '語言 ',
		Chinese: '中文',
		English: '英文',
		loading: '加載中',
		affirm: '確認',
		cancel: '取消'
	},
	home: {
		MyAssets: '我的資產',
		redPacket: '紅包',
		mining: '挖礦',
		blotting: '印跡',
		Ticket: '票務',
		college: '學院',
		more: '更多',
		TPUSparkPlugInSAASMarketplace: 'TPU星火插件SAAS市場',
		ItCurrently: '致力於為區塊鏈項目提供落地應用插件，當前開放紅包插件',
		PartnerRecruitment: '合夥人招募',
		Welcome: '歡迎加入我們成為技術、產品、運營、社區合夥人，一起來推進整個SAAS平臺建設'
	},
	liaotian: {
		RedEnvelopeHall: '紅包大廳',
		transferAccounts: '轉賬',
		redPacket: '紅包',
		orderToBetter: '已搶',
		Bidding: '發包',
		property: '資產',
		GamesArePlayed: '玩法',
		unit: '元'
	},
	dig: {
		NervePledgesForMining: 'TPU質押挖礦',
		MyPledge: '我的質押',
		ThePledgeToRecord: '質押記錄',
		ToPledge: '去質押',
		beFrom: '來自',
		greeting: '祝福語',
		YouGet: '您獲得',
		command: '口令',
		RedEnvelope: '發紅包',
		YouHaveOpenedTheRedEnvelope: '您已拆紅包',
		OpenRedEnvelope: '拆紅包',
		TurnRedEnvelopes: '轉紅包',
		PleaseEnterRedEnvelope: '請輸入紅包指令',
		altogether: '共',
		individual: '個',
		HaveBeenReceiving: '已被領取',
		snag: '搶到',
		phone: "請綁定手機",
		password: "請設置交易密碼"

	},
	fahongbao: {
		SendingRedEnvelope: '發送紅包',
		NeedAPassword: '需要口令',
		need: '需要',
		withNoNeed: '不需要',
		SetThePassword: '設置口令',
		ThroughTheType: '通證類型',
		BonusAmount: '紅包金額',
		pleaseEnter: '請輸入',
		TheNumberOfRedPackets: '紅包個數',
		redEnvelopeType: '紅包類型',
		random: '隨機',
		goHalves: '平分',
		CoverGreetings: '封面祝福語',
		serviceCharge: '服務費',
		WhetherThePublic: '是否公開',
		open: '公開',
		privacy: '私密',
		balance: '余額',
		NotEnvelope: '不足支付支付紅包金額',
		Recharge: '充值',
		GenerateRedEnvelope: '生成紅包',
		MyRecord: '我的記錄',
		ContactUs: '聯系客服',
		PleaseEnterTradingPassword: '請填寫密碼',
		SixPassword: '請填寫6比特純數位',
		hint: '提示',
		InsufficientBalancePlease: '余額不足',
		PleaseEnterYourPassword: '請輸入口令',
		PleaseSelectThePass: '請選擇通證',
		totalAmountCannotBeEmpty: '紅包總額不能為空',
		numberOfRedEnvelopesCannotBeEmpty: '紅包個數不能為空',
		PleaseEnterYourUsernameAndPassword: '請輸入用戶名和密碼',
		affirm: '確認',
		cancel: '取消'
	},
	coffer: {
		MyAssets: '我的資產',
		cloudAssets: '雲端資產',
		availableCapital: '可用資產',
		totalAssets: '資產總額',
		frozenAssets: '凍結資產',
		withdraw: '提現',
		LocalAssets: '本地資產',
		FunctionalIntegration: '功能整合中'
	},
	explain: {
		GameShows: '玩法說明',
		PasswordRedEnvelope: '口令紅包：用戶輸入正確的口令才可以拆紅包',
		OrdinaryHongbao: '普通紅包：先到先得',
		UnloggedUsers: '未登錄用戶需要輸入手機號和驗證碼才能領取紅包',
		RedEnvelopeIsALightweight: '紅包是一個輕量級IM，敬請期待基於IM的互動玩法'
	},
	myPledge: {
		MyPledge: '我的質押',
		operate: '操作',
		AdditionalMortgage: '追加抵押',
		pledgeToRedeem: '質押贖回',
		ExpectedEarnings: '預計今日收益',
		pledgeNumber: '質押數量',
		accumulatedEarnings: '累計收益',
		pledgeOfDays: '質押天數',
		applicationDate: '申請日期',
		Number: '數量',
		currency: '幣種',
		status: '狀態',
		packUp: '收起',
		NoPledgeHasBeenCompleted: '質押完成時間為24小時哦',
		PleaseEnterTransactionPassword: '請輸入交易密碼',
		PleaseEnterAdditionalQuantity: '請輸入追加數量',
		SuccessfulPledgeRedemption: '質押贖回成功',
		gainRecording: '領取收益',
		todayEarnings: '預計今日收益'
	},
	redemptionRecord: {
		pledgeToRecord: '質押記錄',
		NoMore: '沒有更多了',
		serialNumber: '序號',
		operate: '操作',
		Number: '數量',
		status: '狀態',
		IsThePledge: '正在質押',
		BeganToYield: '開始收益',
		RedemptionComplete: '贖回完成',
		ApplicationDate: '申請時間',
		ReturnsTheTime: '收益時間'
	},
	pledge: {
		DigTheNumber: '挖礦數量',
		SinceThe50: '個起',
		all: '全部',
		usable: '可用',
		HaveAlreadyPledged: '已經質押',
		DueOnDemand: '活期',
		predict: '預計',
		StartCalculatingReturns: '開始計算收益',
		ExpectedMonthlyEarnings: '預計每年收益',
		ToPledge: '去質押',
		PleaseEnterTransactionPassword: '請輸入交易密碼',
		pledgeSuccess: '質押成功'
	},
	track: {
		blotting: '印跡',
		BlockchainSourceTrackingCardStoragePlatform: '區塊鏈溯源存證平臺',
		ProductDevelopmentAndDebuggingInProgress: '產品開發調試中'
	},
	ticket: {
		ticketService: '票務',
		LetDigital: '讓數字貨幣支持票務購買，比如火車票、機票、電話卡、加油卡',
		SupportNULS: '支持NULS、TPU、ETH等等',
		SpecialSupportForPerformance: '對演出票務特別支持，能快捷發行和流通數字票務。有演出票務需求場景的朋友們我們一起溝通',
		SupportForTrainTickets: '火車票、機票等的支持在從老版本遷移測試'
	},
	tpuschool: {
		BlockchainCorrelation: '區塊鏈相關',
		learningMaterials: '學習資料',
		caseTeaching: '案例教學',
		solution: '解決方案',
		InCollectingData: '收集資料中'
	},
	more: {
		more: '更多',
		SupportTheSpread: '支持真實、有效聲音的傳播',
		SupportThePromotion: '支持先鋒科技和文化的推廣'
	},
	geren: {
		change: "切換語言",
		personalCenter: '個人中心',
		MyAssets: '我的資產',
		redEnvelopeToRecord: '紅包記錄',
		MentionFillingRecord: '充提記錄',
		gainRecording: '收益記錄',
		personalSetting: '個人設置',
		InviteRewards: '邀請獎勵',
		IWantToFeedback: '我要反饋',
		switchAccount: '切換賬號',
		logOut: '退出登錄',
		phoneNumber: '手機號',
		email: '郵箱地址',
		site: '地址',
		cashFlow: '現金流水'
	},
	redRecode: {
		redRecode: '紅包記錄',
		ISent: '我發出的',
		NoMore: '沒有更多了',
		redPacket: '紅包',
		individual: '個',
		get: '領取',
		status: '狀態',
		InTheDistributed: '分發中',
		HasRecycled: '回收中',
		time: '時間',
		operation: '操作',
		examine: '查看',
		withdraw: '撤回',
		IReceivedThe: '我收到的',
		hint: '提示',
		WithdrawTheSuccess: '撤回成功'
	},
	cashOut: {
		MentionFillingRecord: '充值記錄',
		NoMore: '沒有更多了',
		serialNumber: '序號',
		token: '通證',
		number: '數量',
		time: '時間',
		WithdrawalRecord: '提現記錄',
		status: '狀態',
		clickToView: '點擊查看'
	},
	shouyiRecord: {
		gainRecording: '收益記錄',
		NoMore: '沒有更多了',
		operation: '操作',
		serialNumber: '序號',
		principal: '本金',
		rateInterest: '利率',
		earnings: '收益',
		data: '日期',
		GetBenefits: '領取收益',
		GetTheSuccess: '領取成功'
	},
	Uset: {
		set: '設置',
		SetCellPhoneNumber: '綁定手機',
		SetEmail: '綁定郵箱',
		email: '郵箱',

		loginPassword: '登錄密碼',
		transactionPassword: '交易密碼',
		nicknameIsSet: '昵稱設置',
		PleaseEnterNewNickname: '請輸入新的昵稱',
		PictureUpload: '頭像上傳',
		affirm: '確認',
		cancel: '取消'
	},
	sPhone: {
		email: '郵箱地址',
		phoneNumber: '手機號',
		PleaseEnterYourCellPhoneNumber: '請輸入手機號',
		messageAuthenticationCode: '驗證碼',
		pleaseMessageAuthenticationCode: '請輸入驗證碼',
		getCode: '獲取驗證碼',
		SetCellPhoneNumber: '綁定手機',
		SetEmail: '綁定郵箱',
		YouNeed: '提現和交易需要接收驗證碼，請先設置正確的手機號碼',
		WrongCellPhoneNumber: '手機號碼有誤',
		verificationCode: '手機驗證碼不能為空',
		phoneNumberHasBeenSetSuccessfully: '手機號設置成功'
	},
	sPwd: {
		SetTradePassword: '設置登錄密碼',
		messageAuthenticationCode: '驗證碼',
		PleaseVerificationCode: '請輸入驗證碼',
		getCode: '獲取驗證碼',
		newPassword: '新密碼',
		pleaseNewPassword: '請輸入新密碼',
		affirmPassword: '確認密碼',
		PleaseEnterPasswordAgain: '請再次輸入密碼',
		setPassword: '設置密碼',
		SMSVerificationCodeCannotBeEmpty: '驗證碼不能為空',
		pleaseAffirmPassword: '請確認密碼',
		twoPasswordsDoNotMatch: '兩次密碼不一致',
		setPasswordSuccess: '設置密碼成功',
		email: '郵箱地址',

		phoneNumber: '手機號'
	},
	sPay: {
		TradePasswordBinding: '交易密碼綁定',
		messageAuthenticationCode: '驗證碼',
		PleaseSMSVerificationCode: '請輸入驗證碼',
		getCode: '獲取驗證碼',
		TradePassword: '請輸入交易密碼',
		pleaseEnterTradePassword: '請輸入交易密碼',
		ConfirmTransactionPassword: '請輸入確認密碼',
		pleaseAffirmTradePassword: '請確認交易密碼',
		BindingTradePassword: '綁定交易密碼',
		PleaseEnterCorrectVerificationCode: '請輸入正確的驗證碼',
		PleaseEnterCorrectTransactionPassword: '請輸入正確的交易密碼',
		PleaseEnterCorrectTransactionEmail: '請輸入正確的郵箱',
		twoPasswordsDoNotMatch: '兩次密碼不一致',
		hint: '提示',
		TransactionPasswordChangedSuccessfully: '交易密碼修改成功',
		affirm: '確認',
		email: '郵箱地址',
		phoneNumber: '手機號'
	},
	team: {
		InviteRewards: '邀請獎勵',
		MyInvitation: '我的邀請',
		InviteLink: '邀請鏈接',
		InviteCode: '邀請碼',
		CopyInvitationAddress: '復制邀請地址',
		CopyInvitationCode: '復制邀請碼',
		FaceFaceInvitation: '面對面邀請',
		SweepCodeToInvite: '掃碼邀請',
		MyReward: '我的獎勵',
		InviteNumber: '邀請人數',
		InviteReward: '邀請獎勵',
		rankingList: '排行榜',
		redPacket: '紅包',
		individual: 'individual',
		time: '時間',
		hint: '提示',
		affirm: '確認',
		copySuccess: '復制成功'
	},
	feedback: {
		coupleBack: '反饋',
		WelcomeCommunicateWithTheTeam: '歡迎和團隊溝通',
		PleaseEnterTheCommunicationItems: '請輸入溝通事項',
		ImmediatelySend: '馬上發送',
		FeedbackContentCannotBeEmpty: '反饋內容不能為空',
		FeedbackSuccess: '反饋成功'
	},
	cashFlow: {
		cashFlow: '現金流水',
		NoMore: '沒有更多了',
		serialNumber: '序號',
		operate: '操作',
		flow: '流水',
		data: '日期',
		GetTheSuccess: '領取成功'
	},
	share: {
		laizi: "來自",
		de: "的紅包",
		zong: "總額",
		chai: "拆紅包",
		fas: "發送給朋友",
		baocun: "保存成分享圖",
		chang: "長按上面的圖 保存到本地"
	},
	recharge: {
		one: "打幣地址",
		min: "最小存入",
		fuzhi: "復制地址"
	},
	tixian: {
		shenqing: "提現申請",
		one: "提現通證",
		two: "可用數量",
		dongjie: "凍結數量",
		duanxin: "驗證碼",
		tixian: "提現數量",
		jiaoyi: "交易密碼",
		fuwu: "服務費選擇",
		bangding: "綁定提幣地址",
		fasong: "發送驗證碼",
		fasongqingiqu: "發送請求",
		qingone: "請輸入驗證碼",
		qingtwo: "請輸入提現數量",
		qingthree: "請輸入交易密碼",
		tanone: "提現數量小於最低提現數量",
		tantwo: "提現數量必須大於零",
		tanthree: "提現數量大於可用余額",
		tanfour: "提現",
		tanfive: "請先設置提幣地址",
		tansix: "提現成功，將在24小時內處理",
		tansevw: "請先設置交易密碼",
		zuidi: "最低提現數量",
		keyi: "可提現數量",
		fuwufei: "服務費",
		shoudao: "收到：",
		yzmerr: "驗證碼錯誤",
		pwderr: "交易密碼錯誤",
		tberr: "提幣失敗"
	},
	bangdingdizhi: {
		one: "綁定地址",
		two: "驗證碼",
		three: "請輸入驗證碼",
		huoquyanzhnegm: "獲取驗證碼",
		jiaoyi: "交易密碼",
		qingshuru: "請輸入交易密碼",
		tixian: "提現地址",
		qingtwo: "請輸入綁定地址",
		dizhi: "地址備註",
		qingfour: "請輸入地址備註",
		bangdingdizhi: "綁定地址",
		tanone: "驗證碼不能為空",
		tantwo: "請輸入地址",
		tanthree: "請輸入地址備註",
		yes: "綁定成功"
	},
	phoneCode: {
		A: ['阿富汗 +93+4', '阿爾巴尼亞 +355+8', '阿爾及利亞 +213+12', '美屬薩摩亞 +684+16', '安道爾 +376+20', '安哥拉 +244+24', '安圭拉島 +1264+660', '安提瓜和巴布達 +1268+28', '阿根廷 +54+32', '亞美尼亞 +374+51', '澳大利亞 +61+36', '奧地利 +43+40', '阿塞拜疆 +994+31'],
		B: ['巴哈馬 +1242+44', '巴林 +973+48', '孟加拉國 +880+50', '巴巴多斯 +1246+52', '白俄羅斯 +375+112', '比利時 +32+56', '伯利茲 +501+84', '貝寧 +229+204', '百慕大群島 +1441+60', '玻利維亞 +591+68', '博茨瓦納 +267+72', '巴西 +55+76', '文萊 +673+96', '保加利亞 +359+100', '布基納法索 +226+854', '布隆迪 +257+108'],
		C: ['柬埔寨 +855+116', '喀麥隆 +237+120', '加拿大 +1+124', '開曼群島 +1345+136', '中非共和國 +236+140', '乍得 +235+148', '智利 +56+152', '中國 +86+156', '哥倫比亞 +57+170', '剛果 +242+178', '庫克群島 +682+184', '哥斯達黎加 +506+188', '科特迪瓦 +225+384', '古巴 +53+192', '塞浦路斯 +357+196', '捷克共和國 +420+203'],
		D: ['丹麥 +45+208', '吉布提 +253+262', '多米尼加共和國 +1890+214'],
		E: ['厄瓜多爾 +593+218', '埃及 +20+818', '薩爾瓦多 +503+222', '愛沙尼亞 +372+233', '埃塞俄比亞 +251+231'],
		F: ['斐濟群島 +679+242', '芬蘭 +358+246', '法國 +33+250', '法屬圭亞那 +594+254', '法屬波利尼西亞 +689+258'],
		G: ['加蓬 +241+266', '岡比亞 +220+270', '喬治亞 +995+268', '德國 +49+276', '加納 +233+288', '直布羅陀 +350+292', '希臘 +30+300', '格林納達 +1809+308', '關島 +1671+316', '危地馬拉 +502+320', '幾內亞 +224+324', '圭亞那 +592+328'],
		H: ['海地 +509+332', '洪都拉斯 +504+340', '香港 +852+344', '匈牙利 +36+348'],
		I: ['冰島 +354+352', '印度 +91+356', '印度尼西亞 +62+360', '伊朗 +98+364', '伊拉克 +964+368', '愛爾蘭 +353+372', '以色列 +972+376', '意大利 +39+380'],
		J: ['牙買加 +1876+388', '日本 +81+392', '約旦 +962+400'],
		K: ['哈薩克斯坦 +7+398', '肯尼亞 +254+404', '朝鮮 +850+408', '韓國 +82+410', '科威特 +965+414', '吉爾吉斯斯坦 +331+417'],
		L: ['老撾 +856+418', '拉脫維亞 +371+428', '黎巴嫩 +961+422', '萊索托 +266+426', '利比裏亞 +231+430', '利比亞 +218+434', '列支敦士登 +423+438', '立陶宛 +370+440', '盧森堡 +352+442', '盧旺達 +250+646'],
		M: ['澳門 +853+446', '馬達加斯加 +261+450', '馬拉維 +265+454', '馬來西亞 +60+458', '馬爾代夫 +960+462', '馬裏 +223+466', '馬耳他 +356+470', '馬提尼克島 +596+474', '毛裏求斯 +230+480', '墨西哥 +52+484', '摩爾多瓦 +373+498', '摩納哥 +377+492', '蒙古 +976+496', '蒙特塞拉特 +1664+500', '摩洛哥 +212+504', '莫桑比克 +258+508', '緬甸 +95+104'],
		N: ['納米比亞 +264+516', '瑙魯 +674+520', '尼泊爾 +977+524', '荷蘭 +31+528', '荷屬安的列斯群島 +599+530', '新西蘭 +64+554', '尼加拉瓜 +505+558', '尼日爾 +227+562', '尼日利亞 +234+566', '挪威 +47+578'],
		O: ['阿曼 +968+512'],
		P: ['巴基斯坦 +92+586', '巴拿馬 +507+591', '巴布亞新幾內亞 +675+598', '巴拉圭 +595+600', '秘魯 +51+604', '菲律賓 +63+608', '波蘭 +48+616', '葡萄牙 +351+620', '波多黎各 +1787+630'],
		Q: ['卡塔爾 +974+634'],
		R: ['留尼汪島 +262+638', '羅馬尼亞 +40+642', '俄羅斯 +7+643'],
		S: ['聖盧西亞 +1758+662', '聖文森特和格林納丁斯 +1784+670', '薩摩亞 +685+882', '聖馬力諾 +378+674', '聖多美和普林西比 +239+678', '沙特阿拉伯 +966+682', '塞內加爾 +221+686', '塞舌爾 +248+690', '塞拉利昂 +232+694', '新加坡 +65+702', '斯洛伐克 +421+703', '斯洛文尼亞 +386+705', '所羅門群島 +677+90', '索馬裏 +252+706', '南非 +27+710', '西班牙 +34+724', '斯裏蘭卡 +94+144', '蘇丹 +249+736', '蘇裏南 +597+740', '斯威士蘭 +268+748', '瑞典 +46+752', '瑞士 +41+756', '敘利亞 +963+760'],
		T: ['臺灣 +886+158', '塔吉克斯坦 +992+762', '坦桑尼亞 +255+834', '泰國 +66+764', '多哥 +228+768', '湯加 +676+776', '特立尼達和多巴哥 +1809+780', '突尼斯 +216+788', '土耳其 +90+792', '土庫曼斯坦 +993+795'],
		U: ['烏幹達 +256+800', '烏克蘭 +380+804', '阿拉伯聯合酋長國 +971+784', '英國 +44+826', '美國 +1+840', '烏拉圭 +598+858', '烏茲別克斯坦 +998+860'],
		V: ['委內瑞拉 +58+862', '越南 +84+704'],
		Y: ['也門 +967+887'],
		Z: ['贊比亞 +260+894', '津巴布韋 +263+716'],
	}
}
