import Vue from "vue";
import "./plugins/axios";
import App from "./App/App.vue";
// import VueSocketIO from 'vue-socket.io';
import router from "./router";
import store from "./store";
// import "@/assets/font/iconfont.css";
import "./utils/registerServiceWorker";
// import 'element-theme-chalk';

import './assets/css/global.css'; /*引入公共样式*/
import './assets/font/font.css'; /*引入haoshenti字体*/

import VueI18n from "vue-i18n";
Vue.use(VueI18n)
// 自定义组件 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓

// import tabBar from "@/components/tabBar/tabBar.vue";
// Vue.component("tab-bar", tabBar);
import tabBared from "@/components/tabBared/tabBar.vue";
Vue.component("tab-bared", tabBared);
import yButton from "@/components/yButton/yButton.vue";
Vue.component("y-button", yButton);
import goHome from "@/components/goHome/goHome.vue";
Vue.component("go-home", goHome);
import navBar from "@/components/navBar/navBar.vue";
Vue.component("nav-bar", navBar);


// 自定义组件 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

// v-clipboard ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
import vClipBoard from "vue-clipboard2";
Vue.use(vClipBoard);

// v-clipboard ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑


import VueQrcodeReader from 'vue-qrcode-reader';
Vue.use(VueQrcodeReader);

// // 导入[lib-flexible]用来适配基准值
// import 'amfe-flexible/index.js'

//页面缩放
import "./utils/until.js";

// 主域
import domain from "./utils/url.js";
global.domain = domain;

// 公共方法
import utils from "./utils/until.js";
Vue.prototype.$utils = utils;

// 网络请求封装
import { postAjax, postAjax2, postAjax3, saveLoginSession, scan, newBrowser } from "./utils/http.js";
Vue.prototype.$post = postAjax;
Vue.prototype.$post2 = postAjax2;
Vue.prototype.$post3 = postAjax3;
Vue.prototype.$saveLoginSession = saveLoginSession;
Vue.prototype.$scan = scan;
Vue.prototype.$newBrowser = newBrowser;


// 引入下拉刷新
import VueScroller from 'vue-scroller'
Vue.use(VueScroller)

// 导入 sha256加密 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
import { SHA256 } from "./utils/SHA256.js";
Vue.prototype.$SHA256 = SHA256;

// import "./utils/nabox.js";
//Vue.prototype.$NaboxJSBridge = NaboxJSBridge;


// 初始化CSS
import "./assets/css/common.css";

// import PuzzleVerification from 'vue-puzzle-verification'
// Vue.use(PuzzleVerification)

// 导入 weixin-js-sdk
// import wx from "weixin-js-sdk";
// Vue.prototype.wx = wx;

Vue.config.productionTip = false;

// 导入 vant Ui ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
import {
    Switch,
    PasswordInput,
    NumberKeyboard,
    Calendar,
    Lazyload,
    Image as VanImage,
    Checkbox,
    CheckboxGroup,
    SwipeCell,
    Step,
    Steps,
    AddressList,
    Swipe,
    SwipeItem,
    Col,
    Row,
    Popup,
    DatetimePicker,
    Picker,
    Field,
    Dialog,
    Toast,
    Icon,
    NavBar,
    PullRefresh,
    Button,
    Cell,
    CellGroup,
    RadioGroup,
    Radio,
    Tab,
    Tabs,
    Uploader,
    ImagePreview,
    List,
    Collapse,
    Divider,
    CollapseItem,
    IndexBar,
    IndexAnchor,
    ActionSheet,
    Loading,
    Search,
    Tag,
    DropdownMenu,
    DropdownItem,
    Tabbar,
    TabbarItem,
    Stepper,
    Popover
} from "vant";
Vue.use(Popover);
Vue.use(Switch),
    Vue.use(PasswordInput),
    Vue.use(NumberKeyboard),
    Vue.use(Calendar);
Vue.use(Lazyload);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(VanImage);
Vue.use(SwipeCell);
Vue.use(Step);
Vue.use(Steps);
Vue.use(AddressList)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(TabbarItem);
Vue.use(IndexBar);
Vue.use(Tabbar);
Vue.use(ActionSheet);
Vue.use(IndexAnchor);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(List);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(ImagePreview);
Vue.use(Divider);
Vue.use(DatetimePicker);
Vue.use(Loading);
Vue.use(Search);
Vue.use(Tag);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Stepper);

// 导入 vant Ui ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
let lang = 'zh'
// let lang = 'en'
if (localStorage.getItem('lang')) {
	lang = localStorage.getItem('lang')
}
const i18n = new VueI18n({
    locale: lang,
    messages: {
		'zh': require('./utils/lang/zh.js'),
		'en': require('./utils/lang/en.js'),
        'zhf': require('./utils/lang/zhf.js'),
    }
})



new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
// (function () { var script = document.createElement('script'); script.src = "https://cdn.jsdelivr.net/npm/eruda"; document.body.appendChild(script); script.onload = function () { eruda.init() } })();
