<template>
    <div class="tabBarH">
        <van-tabbar v-model="active" route inactive-color="#999" active-color="#FF4B04">
            <van-tabbar-item to="/newHome">
                <template #icon="props">
                    <img :src="props.active ? icon.actived1 : icon.inactive1"/>
                </template>
                <!-- <span>{{$t('xinzeng.qianbao')}}</span> -->
                <span>{{ $t("tba.home") }}</span>
            </van-tabbar-item>
            <van-tabbar-item to="/quantification">
                <template #icon="props">
                    <img :src="props.active ? icon.actived2 : icon.inactive2"/>
                </template>
                <!-- <span>{{$t('xinzeng.xiaoxi')}}</span> -->
                <span> {{ $t("tba.quant") }}</span>
            </van-tabbar-item>

<!--            <van-tabbar-item to="/tbaTransaction">-->
<!--                <template #icon="props">-->
<!--                    <img :src="props.active ? icon.actived3 : icon.inactive3"/>-->
<!--                </template>-->
<!--                &lt;!&ndash; <span>{{$t('xinzeng.faxian')}}</span> &ndash;&gt;-->
<!--                <span> {{ $t("tba.trade") }}</span>-->
<!--            </van-tabbar-item>-->
            <van-tabbar-item to="/consultingService">
                <template #icon="props">
                    <img :src="props.active ? icon.actived4 : icon.inactive4"/>
                </template>
                <span>{{ $t("tba.article") }}</span>
            </van-tabbar-item>

            <!-- <van-tabbar-item to="/contract">
              <template #icon="props">
                <img :src="props.active ? icon.actived4 : icon.inactive4" />
              </template>
              <span style="color: #999999"> {{ $t("tba.contract") }}</span>
            </van-tabbar-item> -->
            <van-tabbar-item to="/geren">
                <template #icon="props">
                    <img :src="props.active ? icon.actived5 : icon.inactive5"/>
                </template>
                <span>
                  {{ $t("tba.my") }}
                    <!-- {{$t('xinzeng.wode')}} -->
                </span>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "tabBar",
        data() {
            return {
                activeIndex: 0, // 激活状态
                active: 0,
                icon: {
                    actived1: require("../../assets/imgs/home/b1-on.png"),
                    inactive1: require("../../assets/imgs/home/b1.png"),
                    actived2: require("../../assets/imgs/home/b2-on.png"),
                    inactive2: require("../../assets/imgs/home/b2.png"),
                    actived3: require("../../assets/imgs/home/b3-on.png"),
                    inactive3: require("../../assets/imgs/home/b3.png"),
                    actived4: require("../../assets/imgs/home/b4-on.png"),
                    inactive4: require("../../assets/imgs/home/b4.png"),
                    actived5: require("../../assets/imgs/home/b5-on.png"),
                    inactive5: require("../../assets/imgs/home/b5.png"),
                },
            };
        },
        methods: {
            // 点击 tabBar 哪个就变蓝
            onChange(index) {
                console.log(index);
                this.active = index;
            },
        },
    };
</script>

<style lang="less">
    .tabBarH {
        .van-tabbar {
            height: 60px;
            z-index: 2;
        }

        .van-tabbar-item__icon img {
            height: 20px;
        }

        .van-tabbar-item__text {
            margin-top: 3px;
        }
    }
</style>
