import mApp from '../utils/m';
export default {
    name: "App",
    mixins: [mApp],
    data() {
        return {
            
        };
    }
};
